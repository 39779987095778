import { graphql } from "gatsby";
import React from "react";
import Layout from "../components/layout/Layout";
import * as styles from "../styles/project-details.module.css";

export default function ProjectDetails({ data }) {
  const { html } = data.markdownRemark;
  const { title, stack } = data.markdownRemark.frontmatter;

  return (
    <Layout>
      <div className={styles.details}>
        <h2> {title} </h2>
        <h3> {stack} </h3>
        <div className={styles.featured}>
          {/* <GatsbyImage image={getImage(FeaturedImg.childImageSharp.gatsbyImageData)} alt="Projects" />     */}
          {/* <GatsbyImage image={getImage(project.frontmatter.thumbImg.childrenImageSharp[0].gatsbyImageData)} alt="Projects" /> */}
        </div>
        <div
          className={styles.html}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ProjectDetails($slug: String) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        stack
        title
      }
    }
  }
`;
